<template>
  <span>
    <v-icon
      v-if="icon"
      size="19"
    >
      fal fa-fw {{ icons[patient.gender] }}
    </v-icon>
    <template v-if="text">
      {{ $t(patient.gender) | capitalize }}
    </template>
  </span>
</template>
<script>
  export default {
    props: {
      patient: {
        type: Object,
        default: () => ({}),
      },
      text: {
        type: Boolean,
        default: false,
      },
      icon: {
        type: Boolean,
        default: false,
      },
    },
    data: () => ({
      icons: {
        male: 'fa-person',
        female: 'fa-person-dress',
        unknown: 'fa-person-half-dress',
      },
    }),
  }
</script>
